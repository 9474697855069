import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import da from '@angular/common/locales/da';
import en from '@angular/common/locales/en';
import { APP_INITIALIZER, EnvironmentProviders, ErrorHandler, NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SITE_SETTINGS_SERVICE_TOKEN } from '@sitemule/core/abstract-services/site-settings.service';
import { AddAcceptLanguageHttpInterceptor } from '@sitemule/ng-components/http-interceptors/add-accept-language';
import { first } from 'rxjs';
import { appData } from 'src/data';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthConfigService, AUTH_CONFIG_SERVICE_TOKEN } from './auth-services/auth-config.service';
import { AuthService, AUTH_SERVICE_TOKEN } from './auth-services/auth.service';
import { EmptyAuthService } from './auth-services/empty-services/empty-auth.service';
import { IcebreakAuthService } from './auth-services/icebreak-services/icebreak-auth.services';
import { AppErrorHandler } from './error-handlers/app.error-handler';
import { SiteSettingsService } from './services/siteSettings.service';
import { EmptyWebshopService } from './webshop-services/empty-services/empty-webshop.service';
import { IcebreakWebshopService } from './webshop-services/icebreak-services/icebreak-webshop.service';
import { WebshopConfigService, WEBSHOP_CONFIG_SERVICE_TOKEN } from './webshop-services/webshop-config.service';
import { WEBSHOP_SERVICE_TOKEN } from './webshop-services/webshop.service';

const webshopConfig: (Provider | EnvironmentProviders)[] = [];

if (appData.webshopConfig && appData.webshopConfig.type === 'icebreak') {
  webshopConfig.push({
    provide: WEBSHOP_CONFIG_SERVICE_TOKEN,
    useClass: class _ implements WebshopConfigService {
      domain = appData.webshopConfig?.url || '';
    },
  });
  webshopConfig.push({
    provide: WEBSHOP_SERVICE_TOKEN,
    useClass: IcebreakWebshopService,
  });
  webshopConfig.push({
    provide: AUTH_CONFIG_SERVICE_TOKEN,
    useClass: class _ implements AuthConfigService {
      domain = appData.webshopConfig?.url || '';
    },
  });
  webshopConfig.push({
    provide: AUTH_SERVICE_TOKEN,
    useClass: IcebreakAuthService,
  });
} else {
  webshopConfig.push({
    provide: WEBSHOP_SERVICE_TOKEN,
    useClass: EmptyWebshopService,
  });
  webshopConfig.push({
    provide: AUTH_SERVICE_TOKEN,
    useClass: EmptyAuthService,
  });
}

registerLocaleData(en);
registerLocaleData(da);

const translateLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http, '/assets/i18n/', `.json?cb=${new Date().getTime()}`);
export class MissingTranslationHelper implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    console.log(`Missing translation for: ${params.key}`);
    return params.key;
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationHelper,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (translate: TranslateService, authService: AuthService) => () => {
        return authService.initialize().then(() => {
          return new Promise<void>(res => {
            // Builder always maintain `en.json` so do not change the language here
            const availableLanguages = ['da', 'en'];
            const locale = availableLanguages.indexOf(appData.language) ? appData.language : availableLanguages[0];

            translate.addLangs(availableLanguages);
            translate.setDefaultLang(locale);

            translate
              .use(locale)
              .pipe(first())
              .subscribe(() => res());
          });
        });
      },
      deps: [TranslateService, AUTH_SERVICE_TOKEN],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddAcceptLanguageHttpInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler,
    },
    {
      provide: SITE_SETTINGS_SERVICE_TOKEN,
      useClass: SiteSettingsService,
    },
    ...webshopConfig,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
