import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./views/page/page.module').then(mod => mod.PageModule) },
  { path: ':key', loadChildren: () => import('./views/page/page.module').then(mod => mod.PageModule) },
  { path: ':key/:key1', loadChildren: () => import('./views/page/page.module').then(mod => mod.PageModule) },
  { path: ':key/:key1/:key2', loadChildren: () => import('./views/page/page.module').then(mod => mod.PageModule) },
  {
    path: '**',
    loadChildren: () => import('./views/page/page.module').then(mod => mod.PageModule),
    data: { 404: true },
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
