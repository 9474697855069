import { Injectable, signal } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  Basket,
  BasketItem,
  FavoriteBasket,
  WebshopContentMetaResponse,
  WebshopContentResponse,
} from '../webshop.interface';
import { WebshopService } from '../webshop.service';

@Injectable({
  providedIn: 'root',
})
export class EmptyWebshopService implements WebshopService {
  activeBasket = signal<Basket | undefined>(undefined);

  getBasket(): Observable<Basket> {
    throw new Error('Method not implemented.');
  }

  addProductToBasket(productId: string, quantity: number): Observable<BasketItem> {
    throw new Error('Method not implemented.');
  }

  removeProductFromBasket(productId: string): Observable<void> {
    throw new Error('Method not implemented.');
  }

  updateProductInBasket(productId: string, quantity: number): Observable<BasketItem> {
    throw new Error('Method not implemented.');
  }

  updateProductInFavoriteBasket(productId: string, basketId: string, quantity: number): Observable<void> {
    throw new Error('Method not implemented.');
  }

  getFavoriteBaskets(): Observable<Pick<FavoriteBasket, 'id' | 'title'>[]> {
    throw new Error('Method not implemented.');
  }

  getFavoriteBasket(basketId: string): Observable<FavoriteBasket | undefined> {
    throw new Error('Method not implemented.');
  }

  createFavoriteBasket(basketName: string): Observable<Pick<FavoriteBasket, 'title' | 'id'>> {
    throw new Error('Method not implemented.');
  }
  addProductToFavoriteBasket(productId: string, basketId: string, quantity: number): Observable<void> {
    throw new Error('Method not implemented.');
  }
  removeProductToFavoriteBasket(productId: string, basketId: string): Observable<void> {
    throw new Error('Method not implemented.');
  }

  getContentMetaByKey(key: string): Observable<WebshopContentMetaResponse | undefined> {
    return of(undefined);
  }
  getContentByKey(key: string): Observable<WebshopContentResponse | undefined> {
    return of(undefined);
  }
}
