import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { AUTH_CONFIG_SERVICE_TOKEN, AuthConfigService } from '../auth-config.service';
import { User } from '../auth.interface';
import { AuthService } from '../auth.service';

type InitializeResponse =
  | {
      success: false;
    }
  | {
      user: {
        user_id: number;
        name: string;
        email: string;
        language: string;
      };
    };

@Injectable({
  providedIn: 'root',
})
export class IcebreakAuthService implements AuthService {
  initiateLogin(): Promise<void> {
    this.showLoginForm.set(true);
    return Promise.resolve();
  }
  config = inject<AuthConfigService>(AUTH_CONFIG_SERVICE_TOKEN);
  http = inject(HttpClient);

  initialize(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.get<InitializeResponse>(`${this.config.domain}/cms/usr/getCurrentClient`).subscribe({
        error: e => {
          reject(e);
        },
        next: res => {
          this.initialized.set(true);
          if ('user' in res && res.user) {
            this.isLoggedIn.set(true);
            this.user.set({
              id: `${res.user.user_id}`,
              name: res.user.name,
              email: res.user.email,
            });
            resolve();
            return;
          }

          this.isLoggedIn.set(false);
          resolve();
          return;
        },
      });
    });
  }
  isLoggedIn = signal(false);
  initialized = signal(false);
  showLoginForm = signal(false);
  user = signal<User | undefined>(undefined);
  login(username: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post<
          | {
              success: false;
              message: string;
            }
          | {
              success: true;
            }
        >(`${this.config.domain}/cms/user/login`, {
          clientid: username,
          password,
        })
        .subscribe({
          error: e => {
            reject(e);
          },
          next: () => {
            this.initialize().then(resolve).catch(reject);
          },
        });
    });
  }
  logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.post<void>(`${this.config.domain}/cms/user/logoff`, {}).subscribe({
        error: e => {
          reject(e);
        },
        next: () => {
          this.isLoggedIn.set(false);
          this.user.set(undefined);
          resolve();
        },
      });
    });
  }
}
