import { Injectable, signal } from '@angular/core';
import { User } from '../auth.interface';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class EmptyAuthService implements AuthService {
  initiateLogin(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  login(username: string, password: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  logout(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  initialize(): Promise<void> {
    return Promise.resolve().then(() => {
      this.initialized.set(true);
    });
  }
  showLoginForm = signal(false);
  isLoggedIn = signal(false);
  initialized = signal(false);
  user = signal<User | undefined>(undefined);
}
