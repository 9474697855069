import { Injectable, isDevMode } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { SharedErrorHandler } from '@sitemule/core';

@Injectable({
  providedIn: 'root',
})
export class AppErrorHandler extends SharedErrorHandler {
  override handleError(error: any) {
    if (!isDevMode()) {
      Sentry.captureException(error.originalError || error);
    }

    super.handleError(error);
  }
}
