import { InjectionToken, WritableSignal } from '@angular/core';
import { User } from './auth.interface';

export const AUTH_SERVICE_TOKEN = new InjectionToken<AuthService>('AUTH SERVICE TOKEN');

export abstract class AuthService {
  abstract initialize(): Promise<void>;
  abstract initialized: WritableSignal<boolean>;
  abstract isLoggedIn: WritableSignal<boolean>;
  abstract user: WritableSignal<User | undefined>;
  abstract showLoginForm: WritableSignal<boolean>;
  abstract initiateLogin(): Promise<void>;
  abstract login(username: string, password: string): Promise<void>;
  abstract logout(): Promise<void>;
}
