import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { isDevMode } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';

if (!isDevMode()) {
  Sentry.init({
    dsn: 'https://73e1ddda2b8d485295be6933a8a4b6e3@o263138.ingest.sentry.io/4505580578799616',
    integrations: [],
    tracesSampleRate: 0,
    autoSessionTracking: false,
    sendClientReports: false,
  });
}

function bootstrap() {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
}

if (typeof document !== 'undefined') {
  if (document?.readyState === 'complete') {
    bootstrap();
  } else {
    document?.addEventListener('DOMContentLoaded', bootstrap);
  }
}
