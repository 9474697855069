import { InjectionToken, WritableSignal } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Basket,
  BasketItem,
  FavoriteBasket,
  WebshopContentMetaResponse,
  WebshopContentResponse,
} from './webshop.interface';

export const WEBSHOP_SERVICE_TOKEN = new InjectionToken<WebshopService>('WEBSHOP SERVICE TOKEN');

export abstract class WebshopService {
  abstract getContentMetaByKey(key: string): Observable<WebshopContentMetaResponse | undefined>;
  abstract getContentByKey(key: string): Observable<WebshopContentResponse | undefined>;

  abstract getFavoriteBaskets(): Observable<Pick<FavoriteBasket, 'id' | 'title'>[]>;
  abstract getFavoriteBasket(basketId: string): Observable<FavoriteBasket | undefined>;
  abstract createFavoriteBasket(basketName: string): Observable<Pick<FavoriteBasket, 'id' | 'title'>>;
  abstract addProductToFavoriteBasket(productId: string, basketId: string, quantity: number): Observable<void>;
  abstract removeProductToFavoriteBasket(productId: string, basketId: string): Observable<void>;
  abstract updateProductInFavoriteBasket(productId: string, basketId: string, quantity: number): Observable<void>;

  abstract activeBasket: WritableSignal<Basket | undefined>;
  abstract getBasket(): Observable<Basket>;
  abstract addProductToBasket(productId: string, quantity: number): Observable<BasketItem>;
  abstract removeProductFromBasket(productId: string): Observable<void>;
  abstract updateProductInBasket(productId: string, quantity: number): Observable<BasketItem>;
}
