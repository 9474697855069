import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { map, Observable } from 'rxjs';
import { WebshopConfigService, WEBSHOP_CONFIG_SERVICE_TOKEN } from '../webshop-config.service';
import {
  Basket,
  BasketItem,
  FavoriteBasket,
  WebshopContentMetaResponse,
  WebshopContentResponse,
} from '../webshop.interface';
import { WebshopService } from '../webshop.service';
import { IcebreakContentResponse } from './icebreak-webshop.interface';

@Injectable({
  providedIn: 'root',
})
export class IcebreakWebshopService implements WebshopService {
  config = inject<WebshopConfigService>(WEBSHOP_CONFIG_SERVICE_TOKEN);
  http = inject(HttpClient);

  getContentMetaByKey(key: string): Observable<WebshopContentMetaResponse> {
    return this.http.get<IcebreakContentResponse>(`${this.config.domain}/cms/prd/getContentBySeoUrl/${key}`).pipe(
      map(res => {
        if (res.type === 'catalog') {
          return {
            type: res.type,
            title: res.content.title,
            description: '',
          };
        }
        return {
          type: res.type,
          title: res.content.title,
          description: res.content.description,
        };
      })
    );
  }
  getContentByKey(key: string): Observable<WebshopContentResponse> {
    return this.http.get<IcebreakContentResponse>(`${this.config.domain}/cms/prd/getContentBySeoUrl/${key}`).pipe(
      map(res => {
        if (res.type === 'catalog') {
          return {
            type: res.type,
            id: '',
            title: res.content.title,
            description: '',
            url: res.seourl,
            assets: [],
            items: res.content.items.map(i => {
              if ('productNumber' in i && i.productNumber) {
                return {
                  type: 'product',
                  id: i.id,
                  title: i.label,
                  description: i.body,
                  url: i.seourl,
                  assets: i.imageUrl
                    ? [
                        {
                          type: 'image',
                          alt: i.label,
                          link: `${this.config.domain}/${i.imageUrl}`,
                        },
                      ]
                    : [],
                  price: parseFloat(i.unitprice),
                  inventoryStatus: 'AVAILABLE',
                  currency: 'Kr.',
                  includingVAT: true,
                };
              }
              return {
                type: 'catalog',
                id: i.id,
                title: i.label,
                description: '',
                url: i.seourl,

                assets: i.imageUrl
                  ? [
                      {
                        type: 'image',
                        alt: i.label,
                        link: `${this.config.domain}/${i.imageUrl}`,
                      },
                    ]
                  : [],
              };
            }),
          };
        }

        return {
          type: res.type,
          id: '',
          title: res.content.title,
          description: res.content.description,
          url: res.seourl,
          productNumber: res.content.productNumber,
          price: parseInt(res.content.unitprice, 10),
          inventoryStatus: 'AVAILABLE',
          currency: 'Kr.',
          includingVAT: true,
          documents: (res.content.documents || []).map(d => ({
            title: d.title,
            url: d.url,
          })),
          properties: res.content.properties?.filter(prop => prop.prioritized),
          assets:
            res.content?.images
              ?.filter(image => image.type === 'image')
              .map(image => ({
                link: `${this.config.domain}/${image.url}`,
                type: 'image',
                alt: image.title,
              })) || [],
        };
      })
    );
  }
  getFavoriteBaskets(): Observable<Pick<FavoriteBasket, 'title' | 'id'>[]> {
    throw new Error('Method not implemented.');
  }
  getFavoriteBasket(basketId: string): Observable<FavoriteBasket | undefined> {
    throw new Error('Method not implemented.');
  }
  createFavoriteBasket(basketName: string): Observable<Pick<FavoriteBasket, 'title' | 'id'>> {
    throw new Error('Method not implemented.');
  }
  addProductToFavoriteBasket(productId: string, basketId: string, quantity: number): Observable<void> {
    throw new Error('Method not implemented.');
  }
  removeProductToFavoriteBasket(productId: string, basketId: string): Observable<void> {
    throw new Error('Method not implemented.');
  }
  updateProductInFavoriteBasket(productId: string, basketId: string, quantity: number): Observable<void> {
    throw new Error('Method not implemented.');
  }
  activeBasket = signal<Basket | undefined>(undefined);
  getBasket(): Observable<Basket> {
    throw new Error('Method not implemented.');
  }
  addProductToBasket(productId: string, quantity: number): Observable<BasketItem> {
    throw new Error('Method not implemented.');
  }
  removeProductFromBasket(productId: string): Observable<void> {
    throw new Error('Method not implemented.');
  }
  updateProductInBasket(productId: string, quantity: number): Observable<BasketItem> {
    throw new Error('Method not implemented.');
  }
}
